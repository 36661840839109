import React from 'react';
import './CSS/Contato.css';

const Contato = () => {
  return (
    <div>
      <h1>Contato</h1>
      <p>Informações de contato.</p>
    </div>
  );
};

export default Contato;